<template>
    <!-- added submit.prevent to stop the form from submitting itself-->
    <v-form
        v-if="!hideCompleteFullSurvey"
        v-model="isValid"
        ref="form"
        @submit.prevent=""
    >
        <h2 v-if="!isKiosk" class="text-lg lg:text-xl mt-4">{{ completeSurveyTitle }}</h2>
        <div v-if="!isKiosk" class="text-sm mb-4" v-html="completeSurveyText"></div>
        <div :class="contactClass">
            <div class="grid grid-cols-2 gap-4">
                <PhoneNumber
                    :class="{'col-span-2': $device.mobile, 'disabled pointer-events-none': disabled}"
                    label="Mobile"
                    v-model="form.mobile"
                    :required="false"
                    @focus="fieldFocus"
                    @blur="fieldBlur"
                />
                <v-text-field
                    ref="email"
                    hide-details outlined flat solo dense
                    :class="{'col-span-2': $device.mobile, 'disabled pointer-events-none': disabled}"
                    label="Email"
                    v-model="form.email"
                    :rules="[validEmail]"
                    @focus="fieldFocus"
                    @blur="fieldBlur"
                ></v-text-field>
            </div>
        </div>
        <div class="text-center text-sm max-w-3/4 m-auto my-4">
            The survey is anonymous, and your phone number and email won’t be shared or used to spam you about anything.
        </div>
    </v-form>
</template>

<script>
import {mapGetters} from 'vuex'
import {FormRules} from '@/components/shared/mixins/formMixins'
import PhoneNumber from '@/components/basic/PhoneNumber'

export default {
    name: 'CompleteFullSurvey',
    components: {PhoneNumber},
    mixins: [FormRules],
    emits: ['fieldFocus', 'fieldBlur'],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isKiosk: {
            type: Boolean,
            default: false
        },
        completeSurveyTitle: {
            type: String,
            default: 'Have you got 3 minutes?'
        },
        completeSurveyText: {
            type: String,
            default: `
                Thank you for completing this survey. If you have another <u>3 minutes</u> we’d love to ask you a few more questions.
                <b>These surveys are completely anonymous.</b> Your contact details are NOT shared with the practice or doctors.
                We only use your contact details to send you the additional questions.
                Please enter your mobile or email details below and we’ll send you a link.
                You can read more about this in our <a class="font-bold" href="" target="_blank">Privacy Statement</a>.
            `
        }
    },
    data() {
        return {
            isValid: true,
            defaultForm: {}
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form?.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        fieldBlur() {
            this.$emit('fieldBlur')
        },
        fieldFocus() {
            this.$emit('fieldFocus')
        },
    },
    computed: {
        ...mapGetters(['readOnly']),
        form() {
            return this.value || this.defaultForm
        },
        hideCompleteFullSurvey() {
            return this.readOnly
        },
        contactClass() {
            return this.$device.mobile ? 'max-w-screen-sm' : 'flex items-center justify-center'
        }
    },
    watch: {
        form: {
            handler() {
                if (this.form) this.$emit('input', this.form)
            },
            deep: true
        }
    }
}
</script>
