<template>
    <div>
        <component
            v-show="show"
            :is="renderForm"
            v-model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent
        >
            <label class="text-sm text-black block mt-4 mb-2 text-center lg:text-left">{{label}}</label>
            <component
                outlined solo dense flat
                :disabled="disabled"
                :is="renderEditableComponent"
                v-bind="config.vuetifyAttrs"
                :placeholder="config.placeholder"
                v-model="formInput.inputValue"
                :items="config.items"
                :rules="rules"
                :multiple="isMultiSelect"
                @change="changeHandler"
            >
                <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                    </v-chip>
                    <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                    >
                      (+{{ value.length - 1 }} others)
                    </span>
                </template>
            </component>
        </component>
    </div>
</template>

<script>
export default {
    name: 'SelectAndLabel',
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            types: [String, Array]
        },
        config: {
            type: Object,
            default: () => {}
        },
        rules: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: true
        },
        isMultiSelect: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            renderEditableComponent: 'v-select',
            renderForm: 'v-form',
            isValid: true,
            formInput: {inputValue: ''},
            defaultRules: [],
            defaultConfig: {},
            defaultValue: '',
        }
    },
    mounted() {
        if (typeof this.value == 'string' && this.isMultiSelect) this.formInput.inputValue = [this.value]
        else this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        changeHandler() {
            if (!this.validate() || !this.formInput.inputValue) return
            this.$emit('input', this.formInput.inputValue)
            this.$emit('value-changed')
        }
    },
    computed: {
        radioGroupConfig() {
            return this.config || this.defaultConfig
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
    }
}
</script>

