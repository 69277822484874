<template>
    <v-form
        v-model="isValid"
        ref="formInput"
        class=""
        @submit.prevent
    >
        <label class="text-base text-black block mt-5 mb-2">{{label}}</label>
        <div class="grid grid-cols-5 gap-2">
            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                v-model="contact.title"
                v-bind="config.vuetifyAttrs"
                label="Title"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else>{{ contact.title }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                class="col-span-2"
                v-model="contact.firstName"
                v-bind="config.vuetifyAttrs"
                label="First name"
                :rules="rules"
                @change="changeHandler"
            />
            <div class="col-span-2" v-else>{{ contact.firstName }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                class="col-span-2"
                v-model="contact.lastName"
                v-bind="config.vuetifyAttrs"
                label="Last name"
                :rules="rules"
                @change="changeHandler"
            />
            <div class="col-span-2" v-else>{{ contact.lastName }}</div>

            <PhoneNumber
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-3"
                v-model="contact.mobile"
                :config="{ placeholder: 'Mobile' }"
                @change="changeHandler"
            />
            <div class="col-span-3" v-else>{{ getPhoneNumber(contact.mobile) }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                class="col-span-3"
                v-model="contact.position"
                v-bind="config.vuetifyAttrs"
                label="Position"
                :rules="rules"
                @change="changeHandler"
            />
            <div class="col-span-3" v-else>{{ contact.position }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                class="col-span-3"
                v-model="contact.email"
                v-bind="config.vuetifyAttrs"
                label="Email"
                :rules="rules"
                @change="changeHandler"
            />
            <div class="col-span-3" v-else>{{ contact.email }}</div>
        </div>
    </v-form>
</template>

<script>
import {computed, ref} from '@vue/composition-api'
import PhoneNumber from '@/components/basic/PhoneNumber.vue'
import {usePrintFormStore} from '@/stores/usePrintFormStore'
import {storeToRefs} from 'pinia'
import {getPhoneNumber} from '@/utils/phone-number.helpers'

export default {
    name: 'ContactDetails',
    emits: ['click-triggered', 'value-changed', 'input'],
    components: {PhoneNumber},
    props: {
        value: {
            type: Object,
            default: () => ({
                title: '',
                first_name: '',
                last_name: '',
                mobile: {},
                position: '',
                email: ''
            })
        },
        label: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        rules: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const formInput = ref({})
        const isValid = ref(true)
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)
        const contact = computed({
            get: () => props.value,
            set: (newContact) => { emit('input', { ...(newContact || {}) }) }
        })

        // TODO: move to a composition function called rules
        const required = value => (value != null && value != '') || 'Required.'

        const validate = () => {
            isValid.value = formInput.value?.validate?.()
            return isValid.value
        }

        const clearValidate = () => {
            isValid.value = true
            formInput.value?.reset?.()
        }

        const changeHandler =() => {
            validate()
            emit('input', contact.value)
            emit('value-changed')
        }

        return { isValid, formInput, contact, isPrinting, validate, clearValidate, required, changeHandler, getPhoneNumber }
    }
}
</script>
