<template>
    <v-form
        v-model="isValid"
        ref="formInput"
        class=""
        @submit.prevent
    >
        <label class="text-base text-black block mt-5 mb-2">{{label}}</label>
        <div class="grid grid-cols-6 gap-2">
            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                outlined
                v-model="address.unit"
                v-bind="config.vuetifyAttrs"
                label="unit"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="pl-2">{{ address.unit }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-5"
                outlined
                v-model="address.address1"
                v-bind="config.vuetifyAttrs"
                label="address 1"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="col-span-5">{{ address.address1 }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-6"
                outlined
                v-model="address.address2"
                v-bind="config.vuetifyAttrs"
                label="address 2"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="col-span-6 pl-2">{{ address.address2 }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-2"
                outlined
                v-model="address.suburb"
                v-bind="config.vuetifyAttrs"
                label="suburb"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="col-span-2">{{ address.suburb }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-2"
                outlined
                v-model="address.postcode"
                v-bind="config.vuetifyAttrs"
                label="postcode"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="col-span-2">{{ address.postcode }}</div>

            <v-text-field
                v-if="!isPrinting"
                :disabled="disabled"
                class="col-span-2"
                outlined
                v-model="address.state"
                v-bind="config.vuetifyAttrs"
                label="state"
                :rules="rules"
                @change="changeHandler"
            />
            <div v-else class="col-span-2">{{ address.state }}</div>
        </div>
    </v-form>
</template>

<script>
import {computed, ref} from '@vue/composition-api'
import {usePrintFormStore} from '@/stores/usePrintFormStore'
import {storeToRefs} from 'pinia'

export default {
    name: 'Address',
    emits: ['click-triggered', 'value-changed', 'input'],
    props: {
        value: {
            type: Object,
            default: () => ({unit: '', address1: '', address2: '', suburb: '', postcode: '', state: ''})
        },
        label: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        rules: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const formInput = ref({})
        const isValid = ref(true)
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)
        const address = computed({
            get: () => props.value,
            set: (newAddress) => { emit('input', { ...(newAddress || {}) }) }
        })
        const validate = () => {
            isValid.value = formInput.value?.validate?.()
            return isValid.value
        }

        const clearValidate = () => {
            isValid.value = true
            formInput.value?.reset?.()
        }
        const changeHandler =() => {
            validate()
            emit('input', address.value)
            emit('value-changed')
        }

        return { isValid, address, formInput, isPrinting, validate, clearValidate, changeHandler }
    }
}
</script>
