<template>
    <div class="mb-4" :class="componentConfig.class">
        <div
            class="text-center text-grey-2 text-lg px-6"
        >{{finalTitle}}</div>
<!--        <div-->
<!--            v-if="subTitle"-->
<!--            class="text-center text-grey-2 text-sm mt-5 mb-2"-->
<!--        >{{subTitle}}</div>-->
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {ref} from '@vue/composition-api'
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'FormSubtitle',
    props: {
        title: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        }
    },
    setup(props) {
        const propTitle = ref(props.title)
        const { category } = useState(['category'])

        let finalTitle = ref(category.value)
        if (propTitle.value) finalTitle.value = propTitle.value

        //#region computed properties
        const componentConfig = (() => props.config)
        //#endregion

        return {
            finalTitle,
            componentConfig
        }
    }
}
</script>
