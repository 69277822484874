<template>
    <v-form
        v-if="!isLoading"
        v-model="isValid"
        ref="form"
        lazy-validation
        @submit.prevent
    >
        <div v-show="forms.length">
            <div
                v-for="(form, idx) of forms"
                :key="`${form.formId}-${form.version}-idx`"
                :class="getFormClasses(form)"
            >
                <div v-if="!hasQuestions(form) && !form.questionSets">
                    <!-- empty div for now until we think about the NPS -->
                </div>
                <div v-else-if="!hasQuestions(form) && form.questionSets">
                    {{emptyQuestionText}}
                </div>
                <div v-else>
                    <Question
                        ref="questionTree"
                        :question-sets="form.questionSets"
                        :questions="form.questions"
                        :disabled="readOnly"
                        :form="form"
                        @question-sets-updated="updateNumberComplete"
                        @save-form="(qs)=>{callSaveForm(idx, qs)}"
                    />
                    <div :class="btnRowClasses" v-if="!readOnly && showBtnRow(idx)">
                        <v-btn
                            v-for="(action, index) of form.actions"
                            :key="`action-${index}`"
                            class="w-full md:w-auto"
                            :class="action.class"
                            :disabled="(loading && isQpa) || saving"
                            v-bind="action.vuetifyAttrs"
                            @click.stop="callEditOrFunction(action)"
                        >
                            {{getButtonText(action)}}
                            <fa-icon icon="chevron-right" class="ml-2 text-white-pure" />
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-form>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { ref } from "@vue/composition-api"
import GET_QUESTION_IDS_BY_CATEGORY from '@/graphql/queries/getQuestionIdsByCategory.gql'
import GET_QUESTIONS_BY_ID from '@/graphql/queries/getQuestionsById.gql'
import GET_ANSWERED_QUESTION_IDS from '@/graphql/queries/getAnsweredQuestionIds.gql'
import camelcaseKeys from 'camelcase-keys'
import { SharedMethods } from '@/components/shared/mixins/sharedMixins'
import Question from '@/components/forms/partials/QuestionTree'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormBuilderMethods, FormRules } from '@/components/shared/mixins/formMixins'
import { FormMixins } from '@/components/shared/mixins/formSave'
import Loader from '@/assets/loader.svg'
import {getRandomSet} from '@/modules/forms'
import {
    addNode,
    buildQuestionTree,
    mapQuestionConfig
} from '@/modules/formBuilder'
import {DEFAULT_FORM_TITLE} from '@/modules/constants'
import { mapActions as piniaMapActions } from 'pinia'
import {useFormAnswerStore} from '@/stores/useFormAnswerStore'
import {useTenantStore} from '@/stores/useTenantStore'
import { formSetService } from '@/services/form-set.service'
import { onMounted } from '@vue/composition-api'
import { tenantService } from '@/services/tenant.service'
import { formAnswerService } from '@/services/form-answer.service'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import { useKioskFormStore } from '@/stores/useKioskFormStore'

const { useActions, useGetters } = createNamespacedHelpers('')


export default {
    name: 'Form',
    components: { Question, Loader},
    mixins: [ SharedMethods, MessageDialog, FormBuilderMethods, FormMixins, FormRules ],
    props: {
        formProps: {
            type: Object,
            default: () => {}
        },
        formTitle: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        // Next 2 properties are used to get the questions for a random
        questionIds: {
            query: GET_QUESTION_IDS_BY_CATEGORY,
            variables() {
                const category = isNaN(this.category) ? null : +this.category
                let tenantIdList = [this.tenantId]
                if (this.parentTenantId) tenantIdList = [...tenantIdList, this.parentTenantId]

                //NOTE: we need the tenantId as a list here because this gql calls a postgres function
                const query = {
                    tenantId: `{${tenantIdList.join(',')}}`,
                    categoryId: category,
                    formSetId: this.formSetId,
                }
                // if (this.keywordSearch) query.keywordSearch = `%${this.keywordSearch}&`
                if (this.keywordSearch) query.keywordSearch = this.keywordSearch.toLowerCase()
                return {
                    ...query
                }
            },
            skip() {
                this.questionIdsByCategoryLoading = false
                return true
                // let skip = !this.tenantId || !this.parentTenantId || !!this.isExternal || (!this.category && !this.keywordSearch)
                // if (this.formReferrer) skip = false
                // if (skip) this.questionIdsByCategoryLoading = false
                // return skip
            },
            result({ data: { questionIds } }) {
                let questionIdList = camelcaseKeys(questionIds, { deep: true })
                // this.$store.commit('SET_ANSWER_SET', this.genericForm.answer)
                if (questionIdList && questionIdList.length) {
                    questionIdList = questionIdList.map(qi => qi?.questionId)
                    if (this.fullSurvey) this.questionIds = questionIdList
                    else this.questionIds = getRandomSet(questionIdList)
                    // now we have the questions, we can go and get the questions by Id
                    this.forceGetFormSet = !this.fullSurvey && !this.formAnswerId
                    // if (this.$apollo.queries.formSetObj) this.$apollo.queries.formSetObj.refetch()
                    if (this.fullSurvey && !this.formAnswerId) this.$apollo.queries.formWithQWuestions.refetch()
                    //TODO: ssee if we still need this
                    this.getQuestionsById = true
                }
                this.questionIdsByCategoryLoading = false
            }
        },
        questions: {
            query: GET_QUESTIONS_BY_ID,
            variables() {
                // const isFullSurvey = Number(this.fullSurvey)
                //TODO: take into account full survey
                const tenantIdOrCompare = [{
                    tenant_id: {_eq: this.tenantId}
                }]
                const formSetOrCompare = [{
                    question_id: {
                        _in: this.questionIds
                    }
                }, {
                    is_title: {
                        _eq: true
                    }
                },]
                if (!this.formReferrer) {
                    formSetOrCompare.push({
                        on_save_func: {
                            _eq: "sendSurvey"
                        }
                    })
                }

                if (this.parentTenantId) {
                    tenantIdOrCompare.push({
                        tenant_id: {_eq: this.parentTenantId}
                    })
                }

                const query = {
                    tenantIdOrCompare,
                    formSetOrCompare,
                    formSetId: this.formSetId
                }
                return {
                    ...query
                }
            },
            skip() {
                this.questionByIdLoading = false
                return true
                // const skip = !this.tenantId || !!this.isExternal || !this.questionIds.length || this.fullSurvey
                // if (skip) this.questionByIdLoading = false
                // return skip
            },
            result({ data: { questions } }) {
                let questionList = camelcaseKeys(questions, { deep: true })
                // questionList = mapQuestionConfig(questionList, this.$device.mobile)
                questionList = mapQuestionConfig(questionList, false)
                // this.$store.commit('SET_ANSWER_SET', this.genericForm.answer)
                this.questionByIdLoading = true
                this.$nextTick(() => {
                    const form = this.forms[0]
                    if (questionList && questionList.length) form.questions = [...questionList]
                    this.forms[0] = {...form}
                    this.questionByIdLoading = false
                })
            }
        },
        //TODO: check if this is deprecated as we don't need to worry about this for the moment
        // This was used for the full survey that's not used at the moment
        questionIdsForFormAnswer: {
            query: GET_ANSWERED_QUESTION_IDS,
            variables() {
                return {
                    formAnswerId: this.formAnswerId,
                    tenantId: this.tenantId
                }
            },
            skip() {
                if (this.answerLoading) {
                    this.qIdsForAnswerLoading = false
                    return true
                }
                const skip = !this.tenantId || !this.formAnswerId || this.reloadQuestions || !this.qIdsForAnswerLoading || this.answerLoading
                if (skip) {
                    // this.formWithQuestionsLoadingAfterQIds = false
                    this.qIdsForAnswerLoading = false
                }

                // return skip
                return skip
            },
            result({ data: { questionIdsForFormAnswer } }) {
                const questions = camelcaseKeys(questionIdsForFormAnswer, { deep: true })
                this.questionIdsForFormAnswer = questions.reduce((curr, q) => {
                    curr.push(Number(q.questionId))
                    return curr
                }, [])
                // this.$apollo.queries.formWithQuestions.refetch()
                this.qIdsForAnswerLoading = false
            }
        },
    },
    data() {
        return {
            prevRoute: null,
            gettingFormQuestions: false,
            totalProgress: 33,
            formVersion: this.$route.query.v,
            formId: this.$route.query.f,
            formSetId: this.$route.query.fsi || this.$route.params.fsi,
            // TODO: use below when we want to get the random questions
            category: this.$route.query.category,
            change: this.$route.query.change,
            fullSurvey: this.$route.query.fs,
            isExternal: this.$route.query.ext,
            formAnswerIndex: this.$route.query.faidx,
            keywordSearch: this.$route.query.ks,
            formReferrer: this.$route.query?.referrer,
            foundEpisode: null,
            form: {
                ...(this.formProps || {}),
                questions: []
            },
            questionSets: [],
            questionIds: [],
            questionIdsForFormAnswer: [],
            totalQuestions: 0,
            totalComplete: 0,
            defaultButtonText: 'Next',
            isValid: true,
            loading: false,
            disabled: false,
            reloadQuestions: false,
            // first we define savedFormAnswerId so we can watch it for changes
            emptyQuestionText: 'No further questions were found for this survey, you can close the browser at any time.',
            answerLoading: false,
            formWithQuestionsLoading: false,
            formWithQuestionsLoadingAfterQIds: true,
            formLoading: false,
            questionByIdLoading: false,
            questionIdsByCategoryLoading: false,
            qIdsForAnswerLoading: true,
            saving: false,
            hasPhoneOrEmail: false,
            saveTimeout: null,
            saveTimeoutDelay: 1000,
            forceGetFormSet: false,
            getQuestionsById: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    beforeMount() {
        //resetting formAnswer before we do anything
        this.formAnswer = {}
    },
    async mounted() {
        const that = this
        // maybe we can get the previous route name:
        this.setPreviousRoute(this.$route.name)
        this.setFormTitle(this.title)
        this.setIsFullSurvey(this.fullSurvey)

        // we skip the timeout if the form is showing in an iframe or it's the full survey
        if (this.isExternal || this.fullSurvey || !this.isKiosk) return

        this.startIdleTimeout()
        this.$root.$on('reset-time-out', () => {
            that.resetIdleTimeout()
        })
    },
    methods: {
        ...mapActions([
            'setFormTitle', 'setIsFullSurvey', 'setParentTenantId',
            'setPreviousRoute', 'setTenant', 'clearSaveTimeoutQueue',
            'updateIsSaving'
        ]),
        ...piniaMapActions(useFormAnswerStore, ['updateAnswers']),
        ...piniaMapActions(useTenantStore, ['updateTenant']),
        setTitle(title) {
            this.title = title
            this.setFormTitle(this.title)
            this.$emit('update:form-title', this.title)
        },
        callEditOrFunction(element, index) {
            if (!this.editingForm) {
                if (!this.validate()) return
                this.updateIsSaving(true)
                // this.loading = true

                this.functionCallHandler(element.click)
                .then((resp) => {
                    if (resp?.data?.formAnswer) console.log('successfully saved form answer')
                })
                .catch((e) => {
                    console.log('error', e)
                })
                .finally(() => {
                    this.disabled = false
                    this.updateIsSaving(false)
                    // this.loading = false
                })
            } else {
                this.editElement(element, index)
            }
        },
        editElement(element, index) {
            // we only bubble this up if we are editing the form
            if (this.editingForm) this.$root.$emit('edit-element', { element, index })
        },
        validate() {
            let questionTreeIsValid = true
            if (this.$refs.questionTree instanceof Array) {
                for (const component of this.$refs.questionTree) {
                    questionTreeIsValid = component?.validate()
                    if (!questionTreeIsValid) break
                }
            } else {
                questionTreeIsValid = this.$refs.questionTree?.validate()
            }

            this.isValid = this.$refs.form?.validate() && questionTreeIsValid

            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.form.reset()
            return this.isValid
        },
        buildQuestionTree: buildQuestionTree,
        addNode: addNode,
        updateNumberComplete(questionSets) {
            this.totalComplete = this.getNumberComplete(questionSets)
        },
        getButtonText(action) {
            let btnText = ''
            // if we have multiple forms, let's check each of them to see if we actually have questions
            let haveQuestions = false
            for (const form of this.forms) {
                if (form.questions?.length) {
                    haveQuestions = true
                    break
                }
            }

            if (!haveQuestions) btnText = 'Finish'
            else btnText = action.buttonText || this.defaultButtonText
            return this.isSaving || (this.loading && this.isQpa) ? 'Please wait...' : btnText
        },
        async callSaveForm(formIdx, questionSets = []) {

            // if we aren't an external form, we ignore the automatic save for now
            if (!this.formAnswerId && !this.fullSurvey) {
                if (this.isKiosk) this.resetIdleTimeout()
                return
            }

            this.forms[formIdx].questionSets = questionSets;

            //TODO: need to change this
            if (!this.editingForm) {
                if (!this.formAnswerId && !this.validate()) return
                // this.loading = true
                if (this.saveTimeout) clearTimeout(this.saveTimeout)
                const callback = async () => {
                    // if we are already saving, let's reset the timeout and try again
                    if (this.isSaving) {
                        //let's add it to the queue and try again
                        await this.$store.dispatch('addToSaveTimeoutQueue', callback)
                        return
                    }
                    await this.save()
                    // ok we are done, now let's check for any more callbacks
                    const latestCallbackOnTheQueue = this.saveTimeoutQueue?.at(-1)
                    // now let's clear it before we add anymore on
                    this.clearSaveTimeoutQueue()
                    if (latestCallbackOnTheQueue) await latestCallbackOnTheQueue.call(this)
                    this.$emit('update-form-stats')
                    // this.loading = false
                }
                this.saveTimeout = setTimeout(callback, this.saveTimeoutDelay)
            }
        },
        getFormClasses(form) {
            return form?.cssClasses || ''
        },
        hasQuestions(form) {
            return form?.questions?.length || form?.questionSets?.length
        },
        showBtnRow(idx) {
            return idx === this.forms.length - 1
        }
    },
    computed: {
        ...mapState({
            answerSet: state => state.app.answerSet,
            messageResult: state => state.app.messageResult,
        }),
        ...mapGetters(['editingForm', 'parentTenantId', 'saveTimeoutQueue', 'isSaving']),
        btnRowClasses() {
            let classes = this.isExternal ? 'flex flex-row justify-center' : 'grid w-full justify-items-end'
            classes += this.$device.mobile ? ' mt-4' : ' mt-4'
            return classes
        },
        isNps() {
            return this.allForms.findIndex(f => f?.formType?.type.toLowerCase() === 'nps') > -1
        },
        routeType() {
            return this.isNps ? 'nps' : ''
        },
        isQpa() {
            return this.tenant?.parentTenant?.name?.toLowerCase().indexOf('qpa') > -1
        }
    },
    watch: {
        messageResult() {
            if (this.messageResult && Object.keys(this.messageResult).length) {
                this.message = this.messageResult.message
                this.type = this.messageResult.type
                this.showMessage({ duration: 5000 })
            }
        },
    },
    destroyed() {
        this.$root.$off('reset-time-out')
        this.cancelIdleTimeout()
    },
    setup (_, { root }) {
        const { isKiosk: isKioskStore } = useGetters(['isKiosk'])
        const kioskFormStore = useKioskFormStore()
        const {
            startIdleTimeout,
            resetIdleTimeout,
            cancelIdleTimeout,
            showIdleTimeoutModal
        } = kioskFormStore
        const route = root.$route
        const query = route?.query
        const params = route?.params
        const isKiosk = ref(!!route.params?.kiosk)
        if (!isKiosk.value) isKiosk.value = isKioskStore.value
        const formSetId = query.fsi || params.fsi
        const shortName = params?.shortName || ''
        const tenantId = ref(query?.tenant || query?.ti)
        const formAnswerId = ref(params?.formAnswerId || query.fai)
        const formAnswerIndex = query.faidx
        const redirectTo = ref(params.redirectTo)
        const formSet = ref({})
        const forms = ref([])
        const allForms = ref([])
        const isLoading = ref(true)
        const formAnswer = ref({})
        const tenant = ref({})
        const title = ref('Patient Survey')
        const tenantStore = useTenantStore()
        const { updateTenant } = tenantStore
        const {
            setFormTitle,
            setParentTenantId,
            setTenantLogo,
            setTenant
        } = useActions(['setFormTitle', 'setParentTenantId', 'setTenantLogo', 'setTenant'])

        // const tenantId = query.tenant || query?.ti
        onMounted(async () => {
            if (shortName) {
                tenant.value = await tenantService.getTenantByShortName(shortName)
            } else if (tenantId) {
                tenant.value = await tenantService.getTenantById(tenantId)
            }
            if (!tenantId.value) tenantId.value = tenant.value?.tenantId
            setParentTenantId(tenant.value?.parentTenantId)
            setFormTitle(`Thank you for helping us improve ${tenant.value?.name}`)
            setTenantLogo(tenant.value?.logo)
            setTenant(tenant.value)
            updateTenant(tenant.value)
            // console.log('===== in the setup')
            let fa
            if (formAnswerId.value) {
                const resp = await formAnswerService.getFormAnswerByFormAnswerId(formAnswerId.value, tenant.value?.tenantId)
                fa = camelcaseKeys(resp, {deep: true})
                const foundForms = formAnswerIndex !== undefined ? [{ ...fa?.answer[Number(formAnswerIndex)] }] : [...fa?.answer]
                const foundTitles = new Set(foundForms.map(f => f.title))
                title.value = foundTitles.size > 0 ? [...foundTitles][0] : DEFAULT_FORM_TITLE
                formAnswer.value = { ...fa }
                if (!formAnswerId.value) formAnswerId.value = fa.formAnswerId
                forms.value = [...foundForms]
            }
            if (!fa?.answer?.length) {
                let fs = await formSetService.getFormSetById(formSetId, tenant.value?.tenantId, tenant.value?.parentTenantId)
                fs = camelcaseKeys(fs, {deep: true})
                formSet.value = fs
                title.value = formSet.value?.name
                setFormTitle(title.value)
                forms.value = fs?.formSetForms?.reduce((curr, f) => {
                    const form = f?.form
                    if (!form) return curr
                    return [...curr, form]
                }, []) || []
            }
            allForms.value = [...forms.value]
            isLoading.value = false
        })

        return {
            tenantId,
            formAnswerId,
            shortName,
            formSet,
            title,
            allForms,
            forms,
            isLoading,
            formAnswer,
            tenant,
            isKiosk,
            startIdleTimeout,
            resetIdleTimeout,
            cancelIdleTimeout,
            showIdleTimeoutModal,
            redirectTo
        }
    }
}
</script>
