
export const buildQuestionTree = (questionSets, isMobile) => {
    const tree = []
    for (let questionSet of questionSets) {
        if (!questionSet.parentQuestionSetId) {
            //TODO: check why we aren't adding the questions config before pushing to the tree
            tree.push(questionSet)
            // let's check if we need to get the correct config
            if (questionSet?.questions?.length) questionSet.questions = mapQuestionConfig(questionSet.questions, isMobile)
            continue
        }
        addNode(tree, questionSet, isMobile)
    }
    return tree
}

export const addNode = (tree, node, isMobile) => {
    if (!tree || !tree.length) return

    for (const root of tree) {
        const idx = root.questionSets.findIndex(qs => qs.questionSetId === node.questionSetId)
        if (idx > -1) {
            // let's check if we need to get the correct config
            if (node?.questions?.length) node.questions = mapQuestionConfig(node.questions, isMobile)
            // root.questionSets[idx] = { ...node }
            if (!root.mappedQuestionSets) root.mappedQuestionSets = [node]
            else root.mappedQuestionSets.push(node)
            continue
        }
        addNode(root.mappedQuestionSets, node, isMobile)
    }
    return tree
}

export const mapQuestionConfig = (questions, isMobile) => {
    for (let question of questions) {
        // if we are on a mobile, show the mobile config
        if (isMobile && question.configMobile) question.config = question.configMobile
    }

    return questions
}

export const getOrBoolExp = (current, list, op = '_in') => {
    if (!current) current = {_or: []}
    current._or.push({
        [op]: list
    })
    return current
}

export const buildAnswerTree = (parentDuestionSets) => {
    const tree = []
    for (let questionSet of parentDuestionSets) {
        const { questionSetId, parentQuestionSetId, questionSets, questions } = questionSet
        const tempQuestionSet = { questionSetId, parentQuestionSetId, questionSets }
        if (questions?.length) tempQuestionSet.questions = [...questions.map((q) => ({ questionId: q.questionId, dependsOnQuestionId: q.dependsOnQuestionId }))]
        if (questionSets?.length) tempQuestionSet.questionSets = [...questionSets.map((qs) => ({ questionSetId: qs.questionSetId }))]

        if (!parentQuestionSetId) {
            tree.push(tempQuestionSet)
            continue
        }
        addAnswerNode(tree, tempQuestionSet)
    }
    return tree
}

export const addAnswerNode = (tree, node) => {
    if (!tree || !tree.length) return

    for (let root of tree) {
        const idx = root.questionSets?.findIndex(qs => qs.questionSetId === node.questionSetId)
        if (idx > -1) {
            // let's check if we need to get the correct config
            if (node?.questions?.length) node.questions = mapAnswerQuestionConfig(node.questions)
            root.questionSets[idx] = { ...node }
            continue
        }
        addAnswerNode(root.questionSets, node)
    }
    return tree
}

export const mapAnswerQuestionConfig = (questions) => {
    const questionList = []
    for (let question of questions) {
        const { questionId, dependsOnQuestionId, questionCode } = question
        questionList.push({ questionId, dependsOnQuestionId, questionCode, value: null })
    }

    return questionList
}

export const findNodeInTree = (nodes, questionSetId) => {

    let foundNode
    for (const node of nodes) {
        foundNode = findNode(node, questionSetId)
        if (foundNode) break
    }
    return foundNode
}

const findNode = (node, questionSetId) => {
    // first we need to check if we are the correct node
    if (node.questionSetId === questionSetId) {
        return node
    } else {
        return findNodeInTree(node.questionSets, questionSetId)
    }
}

