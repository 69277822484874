import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import {SURVEY_ENGINE_API_HTTP} from '@/modules/constants'

class FormAnswerService {
    constructor () {
        this.url = `${SURVEY_ENGINE_API_HTTP}/form-answers`
    }

    async getFormAnswerByFormAnswerId(formAnswerId, tenantId) {
        let formAnswer = null
        try {
            const { data } = await axios.get(`${this.url}/${formAnswerId}?tenantId=${tenantId}`)
            formAnswer = { ...camelcaseKeys(data, { deep: true }) }
        } catch (e) {
            console.log('error from call', e)
        }
        return formAnswer
    }
}

export const formAnswerService = new FormAnswerService()