<template>
    <div class="max-w-full m-auto text-sm text-grey-10 font-semibold mt-4 mb-2 flex justify-between">
        <div v-html="htmlText"></div>
    </div>
</template>

<script>
import {usePrintFormStore} from '@/stores/usePrintFormStore'
import {storeToRefs} from 'pinia'
import {onMounted, ref} from '@vue/composition-api'

export default {
    name: 'TextOnly',
    props: {
        textToShow: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const htmlText = ref('')
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)

        onMounted(() => {
            htmlText.value = props.textToShow || ''
        })

        return { isPrinting, htmlText }
    }
}
</script>

