<template>
    <div>
        <v-form
            v-show="show"
            v-model="isValid"
            ref="formInput"
            class="c-editable-cell with-details"
            @submit.prevent
        >
            <label class="text-base text-black block mt-4 mb-2 text-center lg:text-left">{{label}}</label>
            <v-text-field
                v-if="!isPrinting"
                ref="formTextField"
                outlined solo dense flat
                :disabled="disabled"
                v-bind="config.vuetifyAttrs"
                :placeholder="config.placeholder"
                v-model="formInput.inputValue"
                :items="config.items"
                :rules="rules"
                @input="changeHandler"
            ></v-text-field>
            <div v-else class="pl-2 pt-1">{{ formInput.inputValue }}</div>
        </v-form>
    </div>
</template>

<script>
import {FormRules} from '@/components/shared/mixins/formMixins'
import {FormMixins} from '@/components/shared/mixins/formSave'
import {usePrintFormStore} from '@/stores/usePrintFormStore'
import {storeToRefs} from 'pinia'

export default {
    name: 'TextFieldAndLabel',
    mixins: [FormRules, FormMixins],
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        rules: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            formInput: {inputValue: ''},
            defaultRules: [],
            defaultConfig: {},
            defaultValue: ''
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        changeHandler() {
            this.validate()
            this.$emit('input', this.formInput.inputValue)
            this.$emit('value-changed')
        }
    },
    computed: {
        radioGroupConfig() {
            return this.config || this.defaultConfig
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        }
    },
    setup() {
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)

        return { isPrinting }
    }
}
</script>

