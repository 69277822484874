<template>
    <component
        v-show="show"
        :is="renderForm"
        v-model="isValid"
        ref="form"
        class="surveyRadioForm m-auto mt-4"
        :class="config.class"
        lazy-validation
        @click.native="editElement"
        @submit.prevent
    >
        <div class="text-black" :class="config.subClass">
            {{label}}
        </div>
        <v-checkbox
            v-for="(checkbox, idx) in checkboxes"
            :disabled="disabled"
            :key="`checkbox-${idx}`"
            :ripple="false"
            :label="checkbox.text"
            :value="checkbox.value"
            :class="checkbox.class || config.subClass"
            v-model="formInput.inputValue"
            v-bind="vuetifyAttrs"
            @change="changeHandler"
        ></v-checkbox>
    </component>
</template>

<script>
import {onMounted, ref, watch, computed} from '@vue/composition-api'

export default {
    name: 'CheckboxGroup',
    emits: ['click-triggered', 'value-changed'],
    props: {
        value: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const formInput = ref({inputValue: []})
        const editingForm = ref(false)
        const isValid = ref(true)
        const form = ref({})

        //#region lifecycle hooks
        onMounted(() => {
            formInput.value.inputValue = props.value || []
        })
        //#endregion

        //region functions
        const editElement = () => {
            if (editingForm.value) emit('click-triggered')
        }

        const changeHandler = () => {
            if (!validate() || formInput.value.inputValue == null) return
            // if (!validate() || formInput.value.inputValue == null) return
            emit('input', formInput.value.inputValue)
            emit('value-changed')
        }

        const validate = () => {
            isValid.value = form.value.validate()
            return isValid.value
        }
        //endregion

        //#region computed props
        const renderForm = computed(() => 'v-form')
        const checkboxes = computed(() => props.config.subComponents)
        const vuetifyAttrs = computed(() => props.config.vuetifyAttrs)
        const gridStartCol = computed(() => {
            let colStart = ''
            if (props?.config?.class.indexOf('col') > -1 && !props?.config?.ignoreColStart) {
                //TODO: make a better way to look for a col start
                // we make the col start in the middle - this is (e.g.) 5 / 2 + 1
                const colClass = props?.config?.class.split().find(c => c.indexOf('col') > -1)
                if (colClass) {
                    const colClassNum = parseInt(colClass.split('-').at(-1))
                    if (!isNaN(colClassNum)) colStart = `col-start-${colClassNum * 2 ? (colClassNum / 2) + 1 : colClassNum / 2}`
                }
            }
            return colStart
        })
        //#endregion

        //#region watches
        watch(() => props.value, (newValue) => formInput.value.inputValue = newValue)
        //#endregion

        return {
            formInput,
            editingForm,
            form,
            isValid,
            renderForm,
            checkboxes,
            vuetifyAttrs,
            gridStartCol,
            editElement,
            validate,
            changeHandler
        }
    }
}
</script>
