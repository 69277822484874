<template>
    <component
        v-show="show"
        :is="renderForm"
        v-model="isValid"
        ref="form"
        :class="config.class"
        lazy-validation
        @click.native="editElement"
    >
        <div class="text-black text-base" :class="config.subClass">
            {{ label }}
        </div>
        <v-checkbox
            v-for="(checkbox, idx) in checkboxes"
            :key="`checkbox-${idx}`"
            :disabled="disabled"
            :ripple="false"
            :label="checkbox.text"
            :value="checkbox.value"
            :class="checkbox.class || config.subClass"
            v-model="formInput.inputValue"
            v-bind="vuetifyAttrs"
            @change="changeHandler"
        ></v-checkbox>
        <v-textarea
            :key="uniqueId"
            :disabled="disabled"
            hide-details
            outlined flat
            class="dark-text"
            auto-grow
            rows="3"
            placeholder="Do you have any other comments?"
            v-model="formInput.comment"
            @change="changeHandler"
        ></v-textarea>
    </component>
</template>

<script>
import { onMounted, ref, computed } from '@vue/composition-api'
import {uuid} from 'vue-uuid'

export default {
    name: "CheckboxGroup",
    emits: ["click-triggered", "value-changed"],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        label: {
            type: String,
            default: "",
        },
        config: {
            type: Object,
            default: () => {},
        },
        show: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const uniqueId = ref(uuid.v4())
        const formInput = ref({ inputValue: [], comment: "" })
        const editingForm = ref(false)
        const isValid = ref(true)
        const form = ref({})

        //#region lifecycle hooks
        onMounted(() => {
            formInput.value = props.value?.inputValue
                ? props.value
                : { comment: "", inputValue: [] }
        })
        //#endregion

        //region functions
        const editElement = () => {
            if (editingForm.value) emit("click-triggered")
        }

        const changeHandler = () => {
            if (!validate() || formInput.value.inputValue == null) return
            emit("input", { ...formInput.value })
            emit("value-changed")
        }

        const validate = () => {
            isValid.value = form.value.validate()
            return isValid.value
        }
        //endregion

        //#region computed props
        const renderForm = computed(() => "v-form")
        const checkboxes = computed(() => props.config.subComponents)
        const vuetifyAttrs = computed(() => props.config.vuetifyAttrs)
        const gridStartCol = computed(() => {
            let colStart = ""
            if (
                props?.config?.class.indexOf("col") > -1 &&
                !props?.config?.ignoreColStart
            ) {
                //TODO: make a better way to look for a col start
                // we make the col start in the middle - this is (e.g.) 5 / 2 + 1
                const colClass = props?.config?.class
                    .split()
                    .find((c) => c.indexOf("col") > -1)
                if (colClass) {
                    const colClassNum = parseInt(colClass.split("-").at(-1))
                    if (!isNaN(colClassNum))
                        colStart = `col-start-${
                            colClassNum * 2
                                ? colClassNum / 2 + 1
                                : colClassNum / 2
                        }`
                }
            }
            return colStart
        })
        //#endregion
        //
        // //#region watches
        // watch(() => props.value, (newValue) => formInput.value = newValue)
        // //#endregion

        return {
            formInput,
            editingForm,
            form,
            isValid,
            renderForm,
            checkboxes,
            vuetifyAttrs,
            gridStartCol,
            uniqueId,
            editElement,
            validate,
            changeHandler,
        }
    },
}
</script>
