import {defineStore} from 'pinia'
import {computed, ref} from '@vue/composition-api'
import {findNodeInTree} from '@/modules/formBuilder'

export const useFormAnswerStore = defineStore('form-answer-store', () => {
    const _questionSets = ref([])
    const _answers = ref([])
    const _formSet = ref({})

    const questionSets = computed(() => _questionSets.value)
    const answers = computed(() => _answers.value)
    const formSet = computed(() => _formSet.value)

    const updateFormSet = (formSet) => {
        _formSet.value = { ...(formSet || {}) }
    }

    const updateQuestionSets = (newQuestionSets) => {
        if (!(newQuestionSets instanceof Array)) return
        _questionSets.value = [...newQuestionSets]
    }

    const updateAnswers = (newAnswers) => {
        if (!(newAnswers instanceof Array)) return
        _answers.value = [...newAnswers]
    }

    const updateAnswer = (formId, version, questionSetId, questionId, questionCode) => {
        // // TODO: - this does not take into account a form that ONLY has questions - need to figure this part out too
        // console.log('==== calling update answer tree', formId, version, questionSetId)
        if (questionSetId === null || formId === undefined) return
        // //TODO: implement this function
        // console.log(formId, version, questionSetId, questionId, questionCode)
        // now we need to find by recursion the correct answer
        const idx = answers.value?.findIndex(f => f.formId === formId && f.version === version)
        const form = answers.value[idx]
        let foundNode 
        if (form) foundNode = findNodeInTree(form?.questionSets, questionSetId)
        // now need to save the foundNode
        return foundNode
        // console.log('======== found anode in the answer tree', foundNode)
    }

    return { questionSets, answers, formSet, updateQuestionSets, updateAnswers, updateFormSet, updateAnswer }
})
